@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Affogato";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("assets/fonts/Affogato-Light.otf");
}

@font-face {
  font-family: "Affogato";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("assets/fonts/Affogato-Regular.otf");
}

@font-face {
  font-family: "Affogato";
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url("assets/fonts/Affogato-Medium.otf");
}

@font-face {
  font-family: "Affogato";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("assets/fonts/Affogato-Bold.otf");
}

@layer base {
  html {
    font-family: Affogato, system-ui, sans-serif;
    scroll-behavior: smooth;
  }

  body {
    background-color: rgb(250 249 247);
  }

  body:before {
    content: "";
    background-image: url("assets/images/JessicaVilela_Simbolo_BORDO.png");
    background-position: -750px -145px;
    background-repeat: no-repeat;
    background-size: 140vh;
    background-attachment: fixed;
    opacity: 0.04;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
  }

  #root {
    position: relative;
    z-index: 1;
  }
}

@layer components {
  .gradient {
    position: relative;
    box-shadow: 0px 11px 8px -5px rgb(0 0 0 / 10%);
  }

  .gradient:after {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0.3) 100%
    );
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
}
